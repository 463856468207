

















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      items: [],
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        {
          value: "format",
          text: "Format",
          align: "right",
        },
        { text: "", value: "actions", align: "right", width: 1 },
      ],
      total: 0,
      deleteDialog: false,
    });

    const fetchIdentifiers = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/badge-template`)
        .then(({ data }) => {
          state.items = data;
          state.total = data.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchIdentifiers);

    const dimensions = [
      {
        text: root.$t("panel.event.modules.receptionConfig.nfcCardVertical"),
        value: JSON.stringify({
          width: 637.5,
          height: 1011,
          margin: 96,
          cornerRadius: 32,
        }),
      },
      {
        text: root.$t("panel.event.modules.receptionConfig.nfcCardHorizontal"),
        value: JSON.stringify({
          width: 1011,
          height: 637.5,
          margin: 96,
          cornerRadius: 32,
        }),
      },

      {
        text: root.$t("panel.event.modules.receptionConfig.A6Vertical"),
        value: JSON.stringify({ width: 1240, height: 1748, margin: 236 }),
      },
      {
        text: root.$t("panel.event.modules.receptionConfig.A6Horizontal"),
        value: JSON.stringify({ width: 1748, height: 1240, margin: 236 }),
      },
    ];

    const deleteItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .delete(`badge-template/${id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.deleteDialog = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.deleteSuccess"),
          });
          fetchIdentifiers();
        })
        .catch(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: root.$tc("layout.misc.deleteFailed"),
          });
        })
        .finally(() => (state.loading = false));
    };

    const copyItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`badge-template/${id}`)
        .then(({ data }) => {
          state.loading = true;

          const name = prompt(
            root.$tc("panel.event.modules.receptionConfig.templateName"),
            `${data.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post(
                `event/${root.$route.params.id}/badge-template`,
                {
                  name: name,
                  format: data.format,
                  items: data.items,
                },
                {
                  headers: {
                    event: root.$route.params.id,
                  },
                }
              )
              .then(fetchIdentifiers)
              .catch((error) => {
                console.log(error);
                alert(
                  root.$tc("panel.event.modules.receptionConfig.copyError")
                );
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return { state, dimensions, deleteItem, copyItem };
  },
});
