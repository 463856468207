var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('event.panel.sidebar.modules.receptionConfig.badges.title')))]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"green","to":{ name: 'panel.event.view.receptionConfig.identifier.add' },"exact":""}},[_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(_vm._s(_vm.$t('event.panel.modules.receptionConfig.badges.add.title')))]),_c('span',{staticClass:"d-md-none"},[_vm._v(_vm._s(_vm.$t('layout.misc.add')))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[(_vm.state.items && _vm.state.items.length)?_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"server-items-length":_vm.state.total,"hide-default-footer":"","disable-sort":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.state.items.indexOf(item) + 1)+".")]}},{key:"item.format",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.dimensions),function(format){return (JSON.stringify(item.format) === format.value)?_c('span',[_vm._v(_vm._s(format.text))]):_vm._e()})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.receptionConfig.identifier.edit', params: { irid: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyItem(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.copy')))])],1)],1),_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(_vm.state.deleteDialog),callback:function ($$v) {_vm.$set(_vm.state, "deleteDialog", $$v)},expression:"state.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.state.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"color":"red","depressed":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)]}}],null,false,3698139601)}):_c('p',[_vm._v(_vm._s(_vm.$t('panel.event.modules.receptionConfig.list.listEmpty')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }